<template>
  <div>
    <div class="zt-block block-1">
      <Comp1 v-if="data && data.id" :data="data" />
    </div>
    <div class="zt-block comp-block">
      <div>
        <img v-if="data && data.createdByComp && data.createdByComp.logo" :src="imgUrl + data.createdByComp.logo+'!width_250px'" />
        <img v-else :src="staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px'" />
        <div class="img-right">
          <div class="name">{{data.createdByComp&&data.createdByComp.name}}</div>
          <div class="label-wrap">
            <label v-for="(tag, i) in tags" :key="i" :class="'color-' + randomInt(4)">{{tag.v}}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="zt-block block-2">
      <div v-if="data && data.id" class="block-main">
        <div class="block-left">
          <div class="sucai" style="margin-bottom:10px;">
            <div class="tit">商品参数</div>
            <div class="flex" style="color:#808080;margin-bottom:8px;">
              <div style="width:300px;" v-if="data.cat">商品类目：{{data.cat.name}}</div>
              <div v-if="data.style">商品风格：{{data.style.name}}</div>
            </div>
            <div class="flex" style="color:#808080;margin-bottom:8px;">
              <div style="width:300px;" v-if="data.moduleNo">型号：{{data.moduleNo}}</div>
              <div v-if="data.dimension && data.dimension != '[]'">商品尺寸：<label v-for="(item,idx) in JSON.parse(data.dimension)" :key="idx">{{item}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label></div>
            </div>
            <div class="attr flex">
              <div class="flex" v-for="(attr,idx) in data.attrs" :key="idx" style="width:300px;color:#808080;margin-bottom:8px;">
                <div>{{attr.name}}：</div>
                <div v-for="(value,n) in attr.values" :key="n">
                  <span v-if="n>0">，</span><span>{{value.name}}</span>
                </div>
              </div>
            </div>
            <div style="color:#808080;margin-bottom:8px;">
              <div class="flex" style="width:600px;" v-if="data.intro">
                <div>商品描述：</div>
                <div class="flex-1">{{data.intro}}</div>
              </div>
            </div>
          </div>
          <div class="sucai">
            <div class="tit">商品素材</div>
            <div v-if="data && data.photoStory && data.photoStory.length>0" class="img-list">
              <img v-for="(item, i) in data.photoStory" :key="i" :src="imgUrl + item+'!width_800px'" />
            </div>
            <div v-else class="img-list">
                <img src="../../../../assets/images/desgin/zwt.png" alt="">
            </div>
          </div>
          <div class="select-bar">
            <div>工厂选择</div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="0">只看现货</el-checkbox>
              <el-checkbox label="1">只看有打样</el-checkbox>
              <el-checkbox label="2">未来一个月可交货</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="order-block">
            <div v-if="data && data.id" class="comp-list">
              <div
                v-for="(item, i) in data.factoryItems"
                :key="i"
                class="comp-item"
                :class="{active: factoryIndex === i}"
                @click="changeFactoryIndex(i)"
              >
                <img v-if="item.factoryComp.logo" :src="imgUrl + item.factoryComp.logo+'!width_250px'" />
                <img v-else :src="staticUrl+'design/placeholder/zhanweilogo%402x.jpg!width_250px'" />
                <div>{{item.factoryComp.name}}</div>
              </div>
            </div>
            <div class="order-main">
              <Comp2 v-if="hackReset && data2 && data2.id" :data="data2" />
            </div>
          </div>
        </div>
        <div class="side">
          <!-- <div v-if="list1 && list1.length>0">
            <h3>同系列查看</h3>
            <div class="list">
              <Comp3 v-for="(item,idx) in list1.slice(0, 3)" :key="idx" :item="item" :type="'design'" />
              <a @click="goto('/sale/mall/home/design')">查看更多</a>
            </div>
          </div> -->
          <div v-if="list2 && list2.length>0">
            <h3>搭配推荐</h3>
            <div class="list">
              <Comp3 v-for="(item,idx) in list2.slice(0, 3)" :key="idx" :item="item" :type="'design'" />
              <a @click="goto('/sale/mall//home/design')">查看更多</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 右侧提示栏 -->
    <FloatPanel></FloatPanel>

  </div>
</template>

<script>
import { IMG_URL_PRE,STATIC_URL_PRE } from "@/config";
import Comp1 from "../common/Comp1.vue";
import Comp2 from "../common/Comp2.vue";
import Comp3 from "../common/Comp3.vue";
import FloatPanel from '../Panel.vue';
import { getDesignItem,getFactoryItem, getDesignItems } from "@/service/mall";
import { getCatDetail } from "@/service/item";

export default {
  components: {
    Comp1,
    Comp2,
    Comp3,
    FloatPanel
  },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      staticUrl:STATIC_URL_PRE,
      queryOpt:{
          where:{
              ids:null,
              key:null,
              catIds:null,
              excludeIds:null,
              isMaster:true,
              seriesIds:null,
              compIds:null,
          },
          page:{
              pageNumber:1,
              pageCount:3,
          },
          order: [
              ["createdAt","desc"]
          ],
      },
      checkList: [],
      factoryIndex: 0,
      data: {},
      data2: {},
      list1: [],
      list2: [],
      cat: {},
      hackReset:true,//刷新子组件
    };
  },
  computed: {
    tags() {
      if (!this.data2.factoryComp || !this.data.createdByComp.aptitude) {
        return [];
      }
      if (!this.data.createdByComp.aptitude.ability_service.jgsx || !this.data.createdByComp.aptitude.ability_service.mczl || !this.data.createdByComp.aptitude.ability_service.xsbq || !this.data.createdByComp.aptitude.ability_service.fwbq) {
        return [];
      }
      const {
        aptitude: {
          ability_service: { tags }
        }
      } = this.data2.factoryComp;
      return [...tags.jgsx, ...tags.mczl, ...tags.xsbq, ...tags.fwbq];
    }
  },
  watch: {
    $route(to,from){
      this.data = null;
      this.refreshItem();
    }
  },
  created() {
    this.refreshItem();
  },
  mounted() {
    // this.list1 = mockData2.rows;
    // this.list2 = mockData2.rows;
  },
  methods: {
    goto(path){
        this.$router.push(path);
    },
    refreshItem() {
      getDesignItem(this.$route.params.id).then(rst=>{
          console.log("getDesignItem",rst);
          if (rst) {
              this.data=rst;
              this.getFactoryItemDetail(rst.factoryItems[0].id);
              if (rst.series && rst.series.id) {
                this.refreshItems(rst.series.id,rst.id);
              }
              this.refreshItems1(rst.id);
          }
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    changeFactoryIndex(i) {
      this.factoryIndex = i;
      const id = this.data.factoryItems[i].id;
      this.getFactoryItemDetail(id);
      this.hackReset = false;
      this.$nextTick(() => {
          this.hackReset = true;
      })
    },
    async getFactoryItemDetail(id) {
      this.data2 = await getFactoryItem(id);
      console.log("getFactoryItem",this.data2);
    },
    randomInt(n) {
      return Math.floor(Math.random() * n + 1);
    },
    // 同系列商品
    refreshItems(id,excludeId) {
      this.queryOpt.where.seriesIds = [id];
      this.queryOpt.where.excludeIds = [excludeId];
      getDesignItems(this.queryOpt).then(rst=>{
          if (rst.rows && rst.rows.length>0) {
            this.total = rst.count;
            this.list1 = rst.rows;
          }
          // console.log("getDesignItems",this.list1);
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    // 搭配推荐商品
    refreshItems1(excludeId) {
      this.queryOpt.where.excludeIds = [excludeId];
      getDesignItems(this.queryOpt).then(rst=>{
          if (rst.rows && rst.rows.length>0) {
            this.total = rst.count;
            this.list2 = rst.rows;
          }
          // console.log("getDesignItems",this.list2);
      }).catch(err => {
          this.$message.error(err.message);
      });
    },
    
  }
};
</script>

<style scoped lang="less">
.zt-block {
  min-width: 1250px;
}
.comp-block {
  
  >div {
    padding: 20px 55px;
    width: 1322px;
    margin: 0 auto;
    display: flex;
    img {
      width: 120px;
      height: 120px;
      margin-right: 24px;
    }
    .img-right {
      .name {
        color: #4d4d4d;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .label-wrap {
        display: flex;
        flex-wrap: wrap;
        width: 450px;
        label {
          width: 88px;
          height: 24px;
          border-radius: 12px;
          margin: 0 24px 12px 0;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          &.color-1 {
            background: rgba(1, 111, 255, 0.2);
            color: #016fff;
          }
          &.color-2 {
            background: rgba(43, 186, 174, 0.2);
            color: #2bbaae;
          }
          &.color-3 {
            background: rgba(230, 162, 60, 0.2);
            color: #e6a23c;
          }
          &.color-4 {
            background: rgba(246, 111, 106, 0.2);
            color: #f66f6a;
          }
        }
      }
    }
  }
}
.block-2 {
  padding: 16px 0;
  color: #4d4d4d;
  .block-main {
    background-color: #f5f5f5;
    display: flex;
    width: 1200px;
    margin: 0 auto;
    .block-left {
      .sucai {
        padding: 16px 0 0 188px;
        .tit {
          font-size: 18px;
          padding-bottom: 10px;
        }
        .img-list {
          width: 680px;
          img {
            width: 100%;
            display: block;
            margin-bottom: 30px;
          }
        }
      }
      .select-bar {
        padding: 9px 0;
        display: flex;
        background-color: #fff;
        margin: 23px 0 8px 12px;
        align-items: center;
        > div:first-child {
          color: #4d4d4d;
          padding: 9px 36px;
          border-right: 1px solid #707070;
          font-size: 18px;
          margin-right: 20px;
        }
      }
      .order-block {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 27px;
        width: 920px;
        .comp-list {
          margin-right: 8px;
          padding: 8px 16px;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

          .comp-item {
            width: 118px;
            height: 160px;
            background-color: #fff;
            padding: 15px;
            margin-bottom: 8px;
            &.active {
              border: 1px solid #016fff;
            }
            cursor: pointer;
            img {
              width: 88px;
              height: 88px;
            }
            div {
              margin-top: 8px;
              color: #4d4d4d;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
            }
          }
        }
        .order-main {
          width: 750px;
        }
      }
    }
    .side {
      width: 253px;
      padding-top: 8px;
      margin-left: 23px;
      > div {
        margin-top: 24px;
        &:first-child {
          margin-top: 0;
        }
        h3 {
          width: 253px;
          height: 79px;
          background: rgba(230, 230, 230, 1);
          border: 1px solid rgba(204, 204, 204, 1);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #4d4d4d;
          font-size: 16px;
        }
        .list {
          padding: 25px 31px;
          background: rgba(245, 245, 245, 1);
          border: 1px solid rgba(204, 204, 204, 1);
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          display: flex;
          flex-direction: column;
          align-items: center;
          .good {
            margin-top: 34px;
            &:first-child {
              margin-top: 0;
            }
          }
          a {
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>